body {
  background: url("../img/background.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  line-height: 22px;
  color: #292929;
  font-weight: bold;
}

h1 {
  line-height: 35px;
}

.MuiInputBase-input {
  color:  #292929 !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color:  #292929 !important;
}


.MuiFormLabel-root.Mui-error {
  color: #f44336 !important;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: red !important;
}

.MuiInputLabel-outlined {
  color:  #292929 !important;
}
